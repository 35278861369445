import React, { Component, ReactNode } from 'react';
import { Rollbar } from '@digsup/rollbar';
import { ErrorCard } from '@digsup/patterns-error';

type ErrorBoundaryProps = {
  authInfo?: any;
  children?: ReactNode;
};

type ErrorBoundaryState = {
  errorCode?: string;
  hasError?: boolean;
  rollbar?: any;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    errorCode: '',
    hasError: false,
    rollbar: new Rollbar({
      accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
      enabled: process.env.NEXT_PUBLIC_DIGSUP_ENV !== 'local',
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: process.env.NEXT_PUBLIC_DIGSUP_ENV,
      payload: {
        person: {
          id: (this.props as ErrorBoundaryProps)?.authInfo?.userId,
        },
        client: {
          javascript: {
            // eslint-disable-next-line camelcase
            source_map_enabled: true,
            // eslint-disable-next-line camelcase
            code_version: process.env.NEXT_PUBLIC_COMMIT_HASH,
            // Optionally have Rollbar guess which frames the error was thrown from
            // when the browser does not provide line and column numbers.
            // eslint-disable-next-line camelcase
            guess_uncaught_frames: true,
          },
          server: {
            root: 'webpack:///./',
          },
        },
      },
    }),
  };

  static getDerivedStateFromError() {
    return {
      errorCode: Math.random().toString(36).slice(2, 6),
      hasError: true,
    };
  }

  componentDidCatch(error) {
    const { errorCode, rollbar } = this.state;
    rollbar.error(`${errorCode} ${String(error)}`, error);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.authInfo?.userId !== this.props?.authInfo?.userId) {
      const { rollbar } = this.state;
      rollbar.configure({
        payload: {
          person: {
            id: this.props?.authInfo?.userId, // required
          },
        },
      });
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <ErrorCard />;
    }

    return children;
  }
}
