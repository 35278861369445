import React from 'react';
import { Modal } from '@digsup/components-modal';
import { Text } from '@digsup/components-text';

type PermissionErrorModalProps = {
  onClose: () => void;
};

export const PermissionErrorModal = ({
  onClose,
}: PermissionErrorModalProps) => (
  <Modal modalIsOpen onRequestClose={onClose} variant="alert" theme="important">
    <Modal.Header title="Action Not Permitted" />
    <Text color="theme-descriptive" className="u-margin-bottom-xlarge">
      Sorry! It appears you are not permitted to perform this action. Please
      contact your account administrator for assistance.
    </Text>

    <Modal.Footer onOk={() => onClose()} okTitle="OK" />
  </Modal>
);
