import React, { useState, ComponentType } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { verify } from 'jsonwebtoken';
import { Provider as ReduxProvider } from 'react-redux'
import { ApolloProvider } from '@apollo/client/react/context';
import { useApollo } from '@/fe-graphql/apollo-client/apollo-client';

import { AppContext } from '@/fe-common/contexts';
import { ErrorBoundary } from '@/fe-components/error-boundary';
import { SystemModals } from '@/fe-components/system-modals';
import { GoogleTagManager } from '@/fe-components/google-tag-manager';
import { reduxStore } from '@/fe-common/redux/redux-store'

import '@digsup/global-styles/global-styles.scss';

const ToastContainer: ComponentType<any> = dynamic(
  () =>
    import('@digsup/components-toast').then(
      m => m.ToastContainer,
    ),
  { ssr: false },
);

const NotificationContainer: ComponentType<any> = dynamic(
  () =>
    import('@/fe-modules/notifications').then(
      m => m.NotificationContainer,
    ),
  { ssr: false },
);

const OfflineDetector: ComponentType<any> = dynamic(
  () =>
    import('@/fe-components/offline-detector').then(
      m => m.OfflineDetector,
    ),
  { ssr: false },
);

type DigsupAppProps = {
  Component: React.ComponentType;
  pageProps: any;
  userId: string;
  accountId: string;
};

function DigsupApp({
  Component,
  pageProps,
  userId,
  accountId,
}: DigsupAppProps) {
  const apolloClient = useApollo(pageProps);
  const [authInfo, setAuthInfo] = useState({ userId, accountId });

  const providerValue = {
    authInfo,
    setAuthInfo,
    userId: authInfo?.userId,
    accountId: authInfo?.accountId,
  };

  return (
    <>
      <Head>
        <title>{`DigsUp!`}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="DigsUp!" />
        <meta property="msapplication-TileColor" content="#27b0d6" />
        <meta property="theme-color'" content="#27b0d6" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="mask-icon"
          type="image/png"
          href="/safari-pinned-tab.svg"
          color="#27b0d6"
        />
      </Head>
      <ErrorBoundary authInfo={authInfo}>
        <GoogleTagManager>
          <ReduxProvider store={reduxStore}>
            <ApolloProvider client={apolloClient}>
              <AppContext.Provider value={providerValue}>
                <OfflineDetector />
                <NotificationContainer />
                <ToastContainer />
                <SystemModals />
                <Component {...pageProps} />
              </AppContext.Provider>
            </ApolloProvider>
          </ReduxProvider>
        </GoogleTagManager>
      </ErrorBoundary>
    </>
  );
}

DigsupApp.getInitialProps = ({ ctx }) => {
  const hasTokenCookie = ctx.req?.cookies?.token;
  if (hasTokenCookie) {
    const payload: any = verify(
      ctx.req?.cookies?.token,
      process.env.JWT_SECRET,
    );
    return {
      userId: payload.id,
      accountId: payload.accountId,
      pageProps: {},
    };
  }
  return { pageProps: {} };
};

export default DigsupApp;
