import React from 'react';
import { Modal } from '@digsup/components-modal';
import { Text } from '@digsup/components-text';

type VerifyPropertyModalProps = {
  onClose: () => void;
};

export const VerifyPropertyModal = ({
  onClose,
}: VerifyPropertyModalProps) => (
  <Modal modalIsOpen onRequestClose={onClose} theme="important">
    <Modal.Header iconName="shield" />
    <Text color="theme-descriptive">
      You must have a verified property claim to send a message. Please contact
      helpdesk@digsup.com for assistance.
    </Text>
  </Modal>
);
