import React from 'react';
import { Link } from '@digsup/components-link';
import { Modal } from '@digsup/components-modal';
import { Text } from '@digsup/components-text';

type VerifyEmailModalProps = {
  onClose: () => void;
};

export const VerifyEmailModal = ({
  onClose,
}: VerifyEmailModalProps) => (
  <Modal modalIsOpen onRequestClose={onClose} theme="important">
    <Modal.Header iconName="shield" />
    <Text color="theme-descriptive">
      Before sending messages, please verify your email address in your{' '}
      <Link href="/my-account">Account</Link>.
    </Text>
  </Modal>
);
