import React from 'react';
import { Modal } from '@digsup/components-modal';
import { Text } from '@digsup/components-text';

type SubscriptionErrorModalProps = {
  onClose: () => void;
};

export const SubscriptionErrorModal = ({
  onClose,
}: SubscriptionErrorModalProps) => (
  <Modal modalIsOpen onRequestClose={onClose} variant="alert" theme="important">
    <Modal.Header title="Action Not Permitted" />
    <Text color="theme-descriptive" className="u-margin-bottom-xlarge">
      Sorry! It appears this action is not permitted by your current
      subscription. Please contact support for assistance.
    </Text>

    <Modal.Footer onOk={() => onClose()} okTitle="OK" />
  </Modal>
);
