import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { LicenseInfo } from '@/fe-common/types/graphql-types';
import { Button } from '@digsup/components-button';
import { PaymentCard } from '@digsup/patterns-card';
import { Modal } from '@digsup/components-modal';
import { Text } from '@digsup/components-text';
import { toast } from '@digsup/components-toast';
import {
  useAccountPayment,
  useAccountSummary,
  useAppContext,
} from '@/fe-hooks';

import { PaymentForm } from './payment-form';
import { ExistingPayment } from './existing-payment';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY);
  }

  return stripePromise;
}

type PaymentModalParams = {
  licenseInfo: LicenseInfo;
  onClose: () => void;
};

/**
 * PaymentModal has to cater for multiple scenarios
 * - Upgrade to a trial but no payment method required
 * - Upgrade to a trial with payment method required (no prev method added)
 * - Upgrade to a trial with payment method required (existing method added)
 * - Upgrade to a regular license (no prev method added)
 * - Upgrade to a regular license (existing method added)
 * 
 * Further, when upgrading to a regular license, we may be doing a
 * pro-rated upgrade (so the messaging needs to be different).
 */
export const PaymentModal = ({ licenseInfo, onClose }: PaymentModalParams) => {
  const { accountId } = useAppContext();
  const [showExistingMethod, setShowExistingMethod] = useState(true);
  const { paymentData, getAccountPaymentData } = useAccountSummary(accountId);
  const {
    handleSetupSucceeded,
    handlePaymentSucceeded,
    handleCreditCardFailed
  } = useAccountPayment();

  useEffect(() => {
    getAccountPaymentData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!licenseInfo || !paymentData) return null;

  const trialAvailed = paymentData.trialAvailed;
  const paymentMethod = paymentData.paymentMethod;
  const claimedProperties = paymentData.claimedProperties.rows;

  const handleStripeError = error => {
    if (error?.type === 'card_error') {
      // Show the error message to the user
      toast(<Text>{error.message}</Text>, {
        theme: 'important',
      });

      // Report the failure to the backend
      handleCreditCardFailed();
    }
  };

  const onCaptureSucceeded = (setupIntent, error) => {
    if (error) {
      handleStripeError(error);
    } else {
      handleSetupSucceeded(setupIntent?.id, licenseInfo.id).then(
        () => onClose()
      );
    }
  };

  const onPaymentSucceeded = (paymentIntent, error) => {
    if (error) {
      handleStripeError(error);
    } else {
      handlePaymentSucceeded(paymentIntent.id, licenseInfo.id).then(
        () => onClose()
      );
    }
  };

  let trialInfo = '';
  let submitText = 'Upgrade Plan';
  let formMode: 'capture' | 'payment' = 'payment';

  // If the account has not availed a trial license before, and the
  // license that we want to upgrade to offers a trial period
  if (trialAvailed === false && licenseInfo.trialDays !== '0') {
    formMode = licenseInfo.trialRequiresCC ? 'capture' : null;
    trialInfo = `${licenseInfo.trialDays} days free, then`;
    submitText = `Start a free ${licenseInfo.trialDays} day trial`;
  }

  const paymentForm = formMode ? (
    paymentMethod ? (
      <Elements stripe={getStripe()}>
        {
          showExistingMethod ? (
            <ExistingPayment
              paymentMethod={paymentMethod}
              formMode={formMode}
              licenseTemplateId={licenseInfo.id}
              submitText={submitText}
              onPaymentSucceeded={onPaymentSucceeded}
              onCaptureSucceeded={onCaptureSucceeded}
              additionalActionText="Add payment method"
              onAdditionalAction={() => { setShowExistingMethod(false) }}
            />
          ) : (
            <PaymentForm
              formMode={formMode}
              licenseTemplateId={licenseInfo.id}
              currentClaims={claimedProperties}
              submitText={submitText}
              onPaymentSucceeded={onPaymentSucceeded}
              onCaptureSucceeded={onCaptureSucceeded}
              additionalActionText="Existing payment method"
              onAdditionalAction={() => { setShowExistingMethod(true) }}
            />
          )
        }
      </Elements>
    ) : (
      <Elements stripe={getStripe()}>
        <PaymentForm
          formMode={formMode}
          licenseTemplateId={licenseInfo.id}
          currentClaims={claimedProperties}
          submitText={submitText}
          onPaymentSucceeded={onPaymentSucceeded}
          onCaptureSucceeded={onCaptureSucceeded}
        />
      </Elements>
    )
  ) : (
    <div className="u-margin-top-large">
      <Button
        variant="primary"
        size="large"
        onClick={() => {
          handleSetupSucceeded(null, licenseInfo.id).then(
            () => onClose()
          );
        }}>
        {submitText}
      </Button>
    </div>
  );

  return (
    <Modal
      modalIsOpen
      onRequestClose={onClose}
      shadow="light"
      size="medium"
      spacing="none"
      withOverlayBackground>
      <PaymentCard
        type={licenseInfo.displayName}
        title={`${licenseInfo.displayName} Plan`}
        features={
          licenseInfo.features.map(feature => feature.description)
        }
        pricePrefix={trialInfo}
        price={`${licenseInfo.monthlyCost}/mo`}
        priceSuffix={`(${licenseInfo.licenseCost})`}>
        {paymentForm}
      </PaymentCard>
    </Modal>
  );
}