import React, { useEffect, useState } from 'react';
import { PermissionIssueTypes, PermissionIssueAction } from '@digsup/constants';
import { LicenseInfo } from '@/fe-common/types/graphql-types';
import { useAppDispatch, useAppSelector } from '@/fe-common/redux';
import { clearPermissionResult } from '@/fe-common/redux/reducers/application';

import { LicenseUpgradeModal } from './license-upgrade-modal';
import { PermissionErrorModal } from './permission-error-modal';
import { SubscriptionErrorModal } from './subscription-error-modal';
import { VerifyEmailModal } from './verify-email-modal';
import { VerifyPropertyModal } from './verify-property-modal';
import { PaymentModal } from './payments';

export const SystemModals = () => {
  const dispatch = useAppDispatch();

  const [licenseInfoForPaymentModal, setLicenseInfoForPaymentModal] = useState(null);
  const [licenseUpgradeModalIsOpen, setLicenseUpgradeModalIsOpen] = useState(false);
  const [permissionModalIsOpen, setPermissionModalIsOpen] = useState(false);
  const [subscriptionModalIsOpen, setSubscriptionModalIsOpen] =
    useState(false);
  const [paymentModalIsOpen, setPaymentModalIsOpen] =
    useState(false);
  const [verifyEmailModalIsOpen, setVerifyEmailModalIsOpen] =
    useState(false);
  const [verifyPropertyModalIsOpen, setVerifyPropertyModalIsOpen] =
    useState(false);

  const permissionResult = useAppSelector(state => state.application.permissionResult);

  useEffect(() => {
    if (!permissionResult) return;
    if (
      permissionResult.hasPermission ||
      !permissionResult?.issues ||
      permissionResult.issues.length === 0
    ) {
      // This does not warrant showing any of the system modals.
      // Clear it out.
      dispatch(clearPermissionResult());
      return;
    }

    // Check for the type of issue being reported by the permission result
    const licenseLimitExceededIssue =
      permissionResult.issues.find(
        issue => issue.type === PermissionIssueTypes.LICENSE_LIMIT_EXCEEDED,
      );
    const licenseLimitExceeded = !!licenseLimitExceededIssue;

    const accoutMissingPermissionIssue =
      permissionResult.issues.find(
        issue => issue.type === PermissionIssueTypes.ACCOUNT_MISSING_PERMISSION,
      );
    const accoutMissingPermission = !!accoutMissingPermissionIssue;

    const emailNotVerified =
      permissionResult.issues.findIndex(
        issue => issue.type === PermissionIssueTypes.EMAIL_NOT_VERIFIED,
      ) !== -1;

    const propertyNotVerified =
      permissionResult.issues.findIndex(
        issue => issue.type === PermissionIssueTypes.NO_VERIFIED_CLAIMED_PROPERTIES,
      ) !== -1;

    // Depending on the reported issue, show the appropriate system dialog
    if (licenseLimitExceeded) {
      // For now we ar not showing the upgrade modal in production environment
      if (
        licenseLimitExceededIssue.action === PermissionIssueAction.SHOW_LICENSE_UPGRADE_MODAL &&
        process.env.NEXT_PUBLIC_DIGSUP_ENV !== 'production' &&
        process.env.NEXT_PUBLIC_DIGSUP_ENV !== 'stage'
      ) {
        setLicenseUpgradeModalIsOpen(true);
      } else {
        setSubscriptionModalIsOpen(true);
      }
    } else if (accoutMissingPermission) {
      // For now we are not showing the upgrade modal in production environment
      if (
        accoutMissingPermissionIssue.action === PermissionIssueAction.SHOW_LICENSE_UPGRADE_MODAL &&
        process.env.NEXT_PUBLIC_DIGSUP_ENV !== 'production' &&
        process.env.NEXT_PUBLIC_DIGSUP_ENV !== 'stage'
      ) {
        setLicenseUpgradeModalIsOpen(true);
      } else {
        setSubscriptionModalIsOpen(true);
      }
    } else if (emailNotVerified) {
      setVerifyEmailModalIsOpen(true);
    } else if (propertyNotVerified) {
      setVerifyPropertyModalIsOpen(true);
    } else {
      // For all other issues, show the permission error modal for now
      setPermissionModalIsOpen(true);
      console.log(permissionResult);
    }
  }, [permissionResult]);

  const licenseUpgradeModal = licenseUpgradeModalIsOpen ? (
    <LicenseUpgradeModal
      permissionObject={permissionResult}
      onClose={() => {
        dispatch(clearPermissionResult());
        setLicenseUpgradeModalIsOpen(false);
      }}
      onChangeLicense={(licenseInfo: LicenseInfo) => {
        dispatch(clearPermissionResult());
        setLicenseUpgradeModalIsOpen(false);
        setLicenseInfoForPaymentModal(licenseInfo);
        setPaymentModalIsOpen(true);
      }}
    />
  ) : null;

  const subscriptionErrorModal = subscriptionModalIsOpen ? (
    <SubscriptionErrorModal
      onClose={() => {
        dispatch(clearPermissionResult());
        setSubscriptionModalIsOpen(false);
      }}
    />
  ) : null;

  const paymentModal = paymentModalIsOpen ? (
    <PaymentModal
      licenseInfo={licenseInfoForPaymentModal}
      onClose={() => {
        setPaymentModalIsOpen(false);
        setLicenseInfoForPaymentModal(null);
      }}
    />
  ) : null;

  const permissionErrorModal = permissionModalIsOpen ? (
    <PermissionErrorModal
      onClose={() => {
        dispatch(clearPermissionResult());
        setPermissionModalIsOpen(false);
      }}
    />
  ) : null;

  const verifyEmailModal = verifyEmailModalIsOpen ? (
    <VerifyEmailModal
      onClose={() => {
        dispatch(clearPermissionResult());
        setVerifyEmailModalIsOpen(false);
      }}
    />
  ) : null;

  const verifyPropertyModal = verifyPropertyModalIsOpen ? (
    <VerifyPropertyModal
      onClose={() => {
        dispatch(clearPermissionResult());
        setVerifyPropertyModalIsOpen(false);
      }}
    />
  ) : null;

  return (
    <>
      {licenseUpgradeModal}
      {subscriptionErrorModal}
      {permissionErrorModal}
      {verifyEmailModal}
      {verifyPropertyModal}
      {paymentModal}
    </>
  );
};
