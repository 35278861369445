import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@/fe-common/types/graphql-types';
import { PaymentCard } from '@digsup/patterns-card';
import { useAccountPayment } from '@/fe-hooks';

type ExistingPaymentParams = {
  formMode: 'capture' | 'payment';
  submitText: string;
  paymentMethod: PaymentMethod;
  licenseTemplateId?: string;
  additionalActionText?: string;
  onAdditionalAction?: () => void;
  onPaymentSucceeded?: (paymentIntent: object, error?: object) => void;
  onCaptureSucceeded: (setupIntent?: object, error?: object) => void;
};

export const ExistingPayment = ({
  formMode,
  submitText,
  paymentMethod,
  licenseTemplateId,
  additionalActionText,
  onAdditionalAction,
  onCaptureSucceeded,
  onPaymentSucceeded,
}: ExistingPaymentParams) => {
  const stripe = useStripe();
  const [processing, setProcessing] = useState(false);
  const { createPaymentIntent } = useAccountPayment();

  const handleSubmit = async () => {
    setProcessing(true);

    if (formMode === 'capture') {
      await onCaptureSucceeded();
    } else if (formMode === 'payment' && onPaymentSucceeded) {
      const clientSecret = await createPaymentIntent(licenseTemplateId, paymentMethod.id);
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret);
      await onPaymentSucceeded(paymentIntent, error);
    }

    setProcessing(false);
  }

  return (
    <PaymentCard.Display
      submitText={submitText}
      isSubmitEnabled={!processing}
      onSubmit={handleSubmit}
      billingAddress={paymentMethod?.billingAddress}
      cardHolderName={paymentMethod?.nameOnCard}
      cardNumber={`**** **** **** ${paymentMethod?.lastFourDigits}`}
      cardExpiry={`${paymentMethod?.expiryMonth}/${paymentMethod?.expiryYear}`}
      additionalActionText={additionalActionText}
      onAdditionalAction={onAdditionalAction}
    />
  );
}