import React from 'react';
import { LicenseInfo, PermissionResult } from '@/fe-common/types/graphql-types';
import { useAccountLicenses } from '@/fe-hooks';
import { PricingPlans } from '@digsup/patterns-pricing-plans';

type LicenseParams = {
  licenseInfo: LicenseInfo,
  onChangeLicense: (licenseInfo: LicenseInfo) => void;
};

const License = ({ licenseInfo, onChangeLicense }: LicenseParams) => {
  const ctaName = licenseInfo.upgradePossible ? "Upgrade" : null;

  return (
    <PricingPlans.Card
      key={licenseInfo.id}
      shadow="none"
      border="default"
      title={licenseInfo.displayName}
      price={licenseInfo.monthlyCost}
      priceSuffix={licenseInfo.licenseCost}
      ctaName={ctaName}
      ctaVariant="primary"
      onClick={ctaName ? () => { onChangeLicense(licenseInfo); } : null}
      features={
        licenseInfo.features.map(feature => ({
          name: feature.description,
          type: feature.availability,
        }))
      }
    />
  );
};

type LicenseUpgradeModalParams = {
  permissionObject: PermissionResult,
  onClose: () => void;
  onChangeLicense: (licenseInfo: LicenseInfo) => void;
};

export const LicenseUpgradeModal = ({
  permissionObject,
  onClose,
  onChangeLicense,
}: LicenseUpgradeModalParams) => {
  const { accountLicensesData } = useAccountLicenses(permissionObject);
  if (!accountLicensesData) return null;

  const licenseInfoArray = accountLicensesData;
  const licenses = licenseInfoArray.map(licenseInfo => (
    <License
      licenseInfo={licenseInfo}
      onChangeLicense={onChangeLicense}
    />
  ));

  return (
    <PricingPlans.Modal
      isOpen
      onRequestClose={onClose}
      title="Upgrade Plan"
      subtitle="To Continue upgrade your plan.">
      <PricingPlans.Row>
        {licenses}
      </PricingPlans.Row>
    </PricingPlans.Modal>
  )
};