import React, { useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { AccountProperty } from '@/fe-common/types/graphql-types';
import { useAccountPayment } from '@/fe-hooks';
import { Form } from '@digsup/components-form';
import { PaymentCard } from '@digsup/patterns-card';
import { StripeInputWrapper, stripeInputStyles } from '@digsup/components-input';

type PaymentFormParams = {
  formMode: 'capture' | 'payment';
  submitText: string;
  referralCodeInfo?: any;
  licenseTemplateId?: string;
  currentClaims: Array<AccountProperty>;
  additionalActionText?: string;
  onAdditionalAction?: () => void;
  onPaymentSucceeded?: (paymentIntent: object, error: object) => void;
  onCaptureSucceeded: (setupIntent: object, error: object) => void;
};

export const PaymentForm = ({
  formMode,
  submitText,
  referralCodeInfo,
  licenseTemplateId,
  currentClaims,
  additionalActionText,
  onAdditionalAction,
  onPaymentSucceeded,
  onCaptureSucceeded,
}: PaymentFormParams) => {
  const stripe = useStripe();
  const elements = useElements();
  const { createSetupIntent, createPaymentIntent } = useAccountPayment();
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const addressOptions = currentClaims.map(accountProperty => ({
    label: accountProperty.property.propertyInfo.propertyName,
    value: accountProperty.id,
  }));

  const submitEnabled = (
    cardNumberComplete && cardExpiryComplete &&
    cardCvcComplete && !processing
  );

  const handleSubmit = async ({ billingAddress, cardholderName }) => {
    setProcessing(true);

    const selectedAccountProperty = currentClaims.find(accountProperty => accountProperty.id === billingAddress.value);
    const selectedProperty = selectedAccountProperty?.property?.propertyInfo;
    const address = {
      line1: selectedProperty?.propertyName,
      city: selectedProperty?.propertyAddress?.city,
      state: selectedProperty?.propertyAddress?.state,
      country: selectedProperty?.propertyAddress?.country,
      postal_code: selectedProperty?.propertyAddress?.postalCode
    };

    if (formMode === 'capture') {
      const clientSecret = await createSetupIntent();
      const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          metadata: {
            account_property_id: selectedAccountProperty.id,
          },
          billing_details: {
            name: cardholderName,
            address,
          },
        },
      });

      await onCaptureSucceeded(setupIntent, error);
    } else if (formMode === 'payment' && onPaymentSucceeded) {
      const clientSecret = await createPaymentIntent(licenseTemplateId);
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          metadata: {
            account_property_id: selectedAccountProperty.id,
          },
          billing_details: {
            name: cardholderName,
            address,
          },
        },
      });

      await onPaymentSucceeded(paymentIntent, error);
    }

    setProcessing(false);
  }

  return (
    <PaymentCard.Form
      isSubmitEnabled={submitEnabled}
      submitText={submitText}
      onSubmit={handleSubmit}
      additionalActionText={additionalActionText}
      onAdditionalAction={onAdditionalAction}
      addressOptions={addressOptions}
      // addAddressHref="#add-address"
      referralCodeInfo={referralCodeInfo}
      cardNumberInput={
        <Form.Field name="cardNumber">
          {({ field, form }) => (
            <StripeInputWrapper {...field}>
              <CardNumberElement
                id="card-number-element"
                options={stripeInputStyles}
                onChange={event => {
                  setCardNumberComplete(event.complete);
                  form.setFieldError(field.name, event?.error?.message)
                }}
              />
            </StripeInputWrapper>
          )}
        </Form.Field>
      }
      cardExpiryInput={
        <Form.Field name="cardExpiry">
          {({ field, form }) => (
            <StripeInputWrapper {...field}>
              <CardExpiryElement
                id="card-expiry-element"
                options={stripeInputStyles}
                onChange={event => {
                  setCardExpiryComplete(event.complete);
                  form.setFieldError(field.name, event?.error?.message)
                }}
              />
            </StripeInputWrapper>
          )}
        </Form.Field>
      }
      cardCvcInput={
        <Form.Field name="cardCvc">
          {({ field, form }) => (
            <StripeInputWrapper {...field}>
              <CardCvcElement
                id="card-cvc-element"
                options={stripeInputStyles}
                onChange={event => {
                  setCardCvcComplete(event.complete);
                  form.setFieldError(field.name, event?.error?.message)
                }}
              />
            </StripeInputWrapper>
          )}
        </Form.Field>
      }
    />
  );
};
