import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Modal } from '@digsup/components-modal';
import { PaymentMethodCard } from '@digsup/patterns-card';
import {
  useAccountPayment,
  useAccountSummary,
  useAppContext,
} from '@/fe-hooks';

import { PaymentForm } from './payment-form';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY);
  }

  return stripePromise;
}

type PaymentMethodModalParams = {
  onClose: () => void;
};

export const PaymentMethodModal = ({ onClose }: PaymentMethodModalParams) => {
  const { accountId } = useAppContext();
  const { handleSetupSucceeded } = useAccountPayment();
  const { paymentData, getAccountPaymentData } = useAccountSummary(accountId);

  useEffect(() => {
    getAccountPaymentData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!paymentData) return null;

  const claimedProperties = paymentData.claimedProperties.rows;
  const onCaptureSucceeded = setupIntent => {
    handleSetupSucceeded(setupIntent.id).then(
      () => onClose()
    );
  };

  return (
    <Modal
      modalIsOpen
      onRequestClose={onClose}
      shadow="light"
      size="medium"
      spacing="none"
      withOverlayBackground>
      <PaymentMethodCard
        title="New Payment Method">
        <Elements stripe={getStripe()}>
          <PaymentForm
            formMode="capture"
            currentClaims={claimedProperties}
            submitText="Create"
            onCaptureSucceeded={onCaptureSucceeded}
          />
        </Elements>
      </PaymentMethodCard>
    </Modal>
  );
}