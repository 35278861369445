import { useEffect, ReactElement } from 'react';
import { useRouter } from 'next/router';
import { pageView } from './utils';

type GoogleTagManagerProps = {
  children: ReactElement<any, any>;
};

export const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({
  children,
}) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView);
    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events]);

  return children;
};
